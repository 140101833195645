<template>
  <router-link :to="{ name: 'Home' }" @click.native="onLogoClick" aria-label="Gucci logo" class="logo">
    <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 28">
      <path d="M48.974 0c.052.725-.025 1.528.026 2.305-.743.051-1.512.026-2.281.026L46.744 0h2.23zM49 3.807l-.026 2.485v21.519H46.72V3.781l.051-.052h2.179l.051.078zM7.885 16.59a.532.532 0 00-.526-.543H5.101v-2.232h5.086V25.98a2.01 2.01 0 01-2 2.02H2c-1.105 0-2-.904-2-2.02V2.061a2.01 2.01 0 012-2.02h8.115v2.367H2.784a.538.538 0 00-.536.541v22.174c0 .298.238.541.536.541h4.57c.295 0 .536-.24.536-.538V16.59h-.005zm14.598 9.367c0 1.113-.849 2.014-1.954 2.014-1.512.026-3.365 0-4.852.026-.846-.051-1.512-.57-1.768-1.346l-.077-.57c-.026-1.165 0-12.144 0-12.144h2.255v11.25c0 .299.241.508.536.508l3.07-.002a.539.539 0 00.537-.541V13.937h2.255v12.02h-.002zm18.676-.21h-1.712c-.295 0-.536-.202-.536-.502v-9.087c0-.298.238-.502.536-.502h1.712v.005c.295 0 .536.241.536.541v1.984h2.255v-2.3c0-1.116-.892-1.973-1.994-1.973h.115-3.378c-1.105 0-1.994.857-1.994 1.973v10.14c0 1.114.892 1.974 1.994 1.974h3.286c.897 0 1.59-.544 1.871-1.372.154-1.062.077-3.408.077-3.408h-2.23v1.983a.538.538 0 01-.536.542m-10.588.002H28.86c-.295 0-.535-.202-.535-.502v-9.087c0-.298.238-.502.535-.502h1.713v.005c.294 0 .533.241.533.541v1.984h2.255v-2.3c0-1.116-.892-1.973-1.994-1.973h.115-3.378c-1.105 0-1.994.857-1.994 1.973v10.14c0 1.114.892 1.974 1.994 1.974h3.286c.897 0 1.59-.544 1.871-1.372.154-1.062.077-3.408.077-3.408h-2.23v1.983a.538.538 0 01-.533.542"/>
    </svg>
  </router-link>
</template>

<script>
import { pushGtmEvent } from '@/utils/analytics-events'

export default {
  name: 'Logo',
  methods: {
    pushGtmEvent,
    onLogoClick () {
      pushGtmEvent('Clicks on Gucci Button', '-', '-')
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  position: fixed;
  width: rem(50px);
  height: rem(25px);
  top: rem($ui-distance-mobile);
  left: rem($ui-distance-mobile);
  z-index: $z-logo;

  @include breakpoint('md-and-up') {
    top: rem($ui-distance-desktop);
    left: rem($ui-distance-desktop);
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
