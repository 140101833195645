<template>
  <div class="map-floor-rooms">
    <router-link
      v-for="room in rooms"
      v-show="!room.reference.hideOnMap"
      :key="room.reference.id"
      class="room-link"
      :class="{ 'isActive': roomHover === room.reference.id}"
      :to="{
        name: 'Room',
        params: {
          floor: floorSlug,
          room: room.reference._slug
        }
      }"
      @mouseenter.native="$emit('link-hover', room)"
      @mouseleave.native="$emit('link-hover', null)"
    >
      <span>
        {{ room.reference.shareTitle }}
      </span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'MapFloorRooms',
  inheritAttrs: false,
  props: {
    rooms: { type: Array },
    roomHover: { type: String }
  },
  computed: {
    floorSlug () {
      return this.$attrs._slug
    }
  }
}
</script>

<style lang="scss" scoped>
.map-floor-rooms {
  @include breakpoint('md-and-up') {
    padding-bottom: rem(60px);
  }
}

.room-link {
  display: block;
  text-align: left;
  font-size: rem(30px);
  line-height: 1.3;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-decoration: none;
  color: $c-white;

  @include breakpoint('lg-only') {
    font-size: rem(24px);
  }

  @include breakpoint('md-and-down') {
    font-size: rem(18px);
  }

  span {
    opacity: 0.3;
    transition: opacity 0.25s ease-in-out;
  }

  &:hover,
  &.isActive {
    span {
      opacity: 1;
    }
  }

  &:not(:last-child) {
    padding-bottom: rem(14px);
  }
}
</style>
