// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/fonts/GucciSansProPoster-Book.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/fonts/GucciSansProPoster-Book.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./assets/fonts/GucciSansProPoster-LightItalic.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./assets/fonts/GucciSansProPoster-LightItalic.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./assets/fonts/GucciSansProPoster-MediumItalic.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./assets/fonts/GucciSansProPoster-MediumItalic.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face{font-family:\"Gucci Sans Pro\";font-style:normal;font-display:swap;font-weight:100;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\")}@font-face{font-family:\"Gucci Sans Pro\";font-style:italic;font-weight:300;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\")}@font-face{font-family:\"Gucci Sans Pro\";font-style:italic;font-weight:400;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\")}.fade-enter-active,.fade-leave-active{-webkit-transition:opacity .5s;transition:opacity .5s}.fade-enter,.fade-leave-to{opacity:0}.font-italic{font-style:italic;font-weight:300}.text-uppercase{text-transform:uppercase}::-moz-selection{color:#baa18d;background:#fff}::selection{color:#baa18d;background:#fff}html,body{width:100%;height:100%;overflow:hidden !important;font-family:\"Gucci Sans Pro\",\"Open Sans\",sans-serif;font-size:1rem;line-height:1.5rem;color:#fff}.app{width:100%;height:100%;overflow:hidden}h1,.h1{font-size:3.125rem;line-height:3.75rem;font-weight:normal}h2,.h2,.h2-spaced{font-size:1.875rem;line-height:1.2;font-weight:normal}.h2-spaced{letter-spacing:.05em}h3{font-size:1.25rem;line-height:1.5;font-weight:normal}button{outline:none}", ""]);
// Exports
module.exports = exports;
