<template>
  <div class="image-wrapper">
    <img
      :src="'/media/' + mapImage.id"
      width="100%"
      height="100%"
    >
    <router-link
      v-for="room in rooms"
      v-show="!room.reference.hideOnMap || $route.params.room === room.reference._slug"
      :key="room.reference.id"
      class="point"
      :class="{ 'isActive': roomHover === room.reference.id}"
      :style="{
        left: room.position.x + '%',
        top: room.position.y + '%'
      }"
      :to="{
        name: 'Room',
        params: {
          floor: floorSlug,
          room: room.reference._slug
        }
      }"
      @mouseenter.native="$emit('dot-hover', room)"
      @mouseleave.native="$emit('dot-hover', null)"
    >
      <img class="room-preview" :src="'/media/' + room.reference.imagePreview.id">
      <span class="circle">
        <span class="inner"></span>
      </span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'MapFloorImage',
  inheritAttrs: false,
  props: {
    mapImage: { type: Object },
    rooms: { type: Array },
    roomHover: { type: String }
  },
  computed: {
    floorSlug () {
      return this.$attrs._slug
    }
  }
}
</script>

<style lang="scss" scoped>
.image-wrapper {
  position: relative;
}

img {
  pointer-events: none;
}

.point {
  position: absolute;
  display: block;
  width: rem(12px);
  height: rem(12px);
  z-index: 2;
  cursor: pointer;

  &:hover,
  &.isActive {
    z-index: 3;
  }
}

.circle,
.inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center center;
  transition: transform 0.25s ease-in-out, opacity 0.25s;
}

.circle {
  display: block;
  width: rem(12px);
  height: rem(12px);
  border-radius: 50%;
  background-color: $c-white;
  margin-top: rem(-6px);
  margin-left: rem(-6px);
  z-index: 2;

  .point:hover &,
  .point.isActive & {
    transform: scale(2.5);
  }
}

.inner {
  opacity: 0;
  width: rem(4px);
  height: rem(4px);
  margin-top: rem(-2px);
  margin-left: rem(-2px);
  border-radius: 50%;
  background-color: $c-black;
  transform: scale(0);

  .point:hover &,
  .point.isActive & {
    opacity: 1;
    transform: scale(1);
  }
}

.room-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 180px;
  height: 90px;
  opacity: 0;
  z-index: 1;
  transform: translate(-50%, -40%);
  transition: transform 0.25s ease-in-out, opacity 0.2s;

  @include breakpoint ('lg-only') {
    width: 160px;
    height: 80px;
  }

  @include breakpoint ('md-only') {
    width: 120px;
    height: 60px;
  }

  @include breakpoint('md-and-up') {
    .point:hover &,
    .point.isActive & {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
