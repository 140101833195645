<template>
  <div class="floors-list">
    <button
      v-for="item in sortedList"
      :key="item.id"
      @click="$emit('click-item', item)"
      :aria-label="`Change floor to ${item.title}`"
      :class="{ 'active': item._slug === current._slug }"
      type="button"
    >
      {{ item.title }}
    </button>
  </div>
</template>

<script>
import { sortBy } from 'lodash'

export default {
  name: 'MapFloorsList',
  props: {
    list: { type: Array },
    current: { type: Object }
  },
  computed: {
    sortedList () {
      return sortBy(this.list, ['sortOrder'], ['asc'])
    }
  }
}
</script>

<style lang="scss" scoped>
.floors-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: rem(80px);
  z-index: 3;
  @include breakpoint('md-and-up') {
    position: absolute;
    top: rem($ui-distance-desktop);
    left: 20%;
    right: 20%;
    width: auto;
    margin-top: 0;
  }
}

button {
  letter-spacing: 0.05em;
  margin: rem(0 12px);
  font-weight: 500;
  font-size: rem(16px);
  line-height: rem(30px);
  opacity: 0.3;
  transition: opacity 0.25s ease-in-out;

  @include breakpoint('md-and-up') {
    font-size: rem(20px);
  }

  &.active {
    opacity: 1;
    pointer-events: none;
  }
}
</style>
