var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"src":'/media/' + _vm.mapImage.id,"width":"100%","height":"100%"}}),_vm._v(" "),_vm._l((_vm.rooms),function(room){return _c('router-link',{directives:[{name:"show",rawName:"v-show",value:(!room.reference.hideOnMap || _vm.$route.params.room === room.reference._slug),expression:"!room.reference.hideOnMap || $route.params.room === room.reference._slug"}],key:room.reference.id,staticClass:"point",class:{ 'isActive': _vm.roomHover === room.reference.id},style:({
      left: room.position.x + '%',
      top: room.position.y + '%'
    }),attrs:{"to":{
      name: 'Room',
      params: {
        floor: _vm.floorSlug,
        room: room.reference._slug
      }
    }},nativeOn:{"mouseenter":function($event){return _vm.$emit('dot-hover', room)},"mouseleave":function($event){return _vm.$emit('dot-hover', null)}}},[_c('img',{staticClass:"room-preview",attrs:{"src":'/media/' + room.reference.imagePreview.id}}),_vm._v(" "),_c('span',{staticClass:"circle"},[_c('span',{staticClass:"inner"})])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }