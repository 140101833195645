import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from '../routes'

// Avoid NavigationDuplicated error
// see: https://stackoverflow.com/a/62331463/13461377
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err
  })
}

// docs: https://router.vuejs.org/en/
Vue.use(VueRouter)

export default new VueRouter({
  mode: 'hash',
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
