export const FIT_FILL = 'fill'
export const FIT_SCALE = 'scale'
export const FIT_CROP = 'crop'
export const FIT_CLIP = 'clip'
export const FIT_MIN = 'min'
export const FIT_MAX = 'max'

export const CROP_FOCALPOINT = 'focalpoint'
export const CROP_ENTROPY = 'entropy'

export const CROP_TOP_LEFT = 'top,left'
export const CROP_TOP_RIGHT = 'top,right'
export const CROP_BOTTOM_LEFT = 'bottom,left'
export const CROP_BOTTOM_RIGHT = 'bottom,right'

const cropTypes = [CROP_FOCALPOINT, CROP_ENTROPY, CROP_TOP_LEFT, CROP_TOP_RIGHT, CROP_BOTTOM_LEFT, CROP_BOTTOM_RIGHT]

const fitTypes = [FIT_FILL, FIT_SCALE, FIT_CROP, FIT_CLIP, FIT_MIN, FIT_MAX]

const metadataCache = {}
/**
 *
 * @param mediaFile
 * @returns {*}
 */
export const getMetaData = (mediaFile) => {
  if (!mediaFile) throw new Error('A MediaFile must be supplied')
  if (!mediaFile.metaData) throw new Error('"metaData" field must be included in the query for the mediaFile')

  if (!metadataCache[mediaFile.id]) {
    metadataCache[mediaFile.id] = JSON.parse(mediaFile.metaData)
  }
  return metadataCache[mediaFile.id]
}
/**
 *
 * @param mediaFile
 * @returns {*}
 */
export const getLazySrc = (mediaFile) => {
  return getMetaData(mediaFile)['image-lazy-src']
}
/**
 *
 * @param mediaFile
 * @returns {number}
 */
export const getWidth = (mediaFile) => {
  const metaData = getMetaData(mediaFile)
  return parseInt(metaData['image-width'])
}
/**
 *
 * @param mediaFile
 * @returns {number}
 */
export const getHeight = (mediaFile) => {
  const metaData = getMetaData(mediaFile)
  return parseInt(metaData['image-height'])
}
/**
 *
 * @param mediaFile
 * @returns {number}
 */
export const getAspectRatio = (mediaFile) => {
  return getWidth(mediaFile) / getHeight(mediaFile)
}
/**
 *
 * @param mediaFile
 * @param format
 * @param width
 * @param height
 * @param maxWidth
 * @param maxHeight
 * @param breakpointSize
 * @param quality
 * @param aspectRatio
 * @param fit
 * @param fillColor
 * @param crop
 * @param focalPoint
 * @param blur
 * @returns {string}
 */
export const optimizedImageURL = (mediaFile, { format, width, height, maxWidth, maxHeight, breakpointSize, quality, aspectRatio, fit, fillColor = 'black', crop, focalPoint = { x: 0.5, y: 0.5 }, blur } = {}) => {
  if (!mediaFile) { throw new Error('A MediaFile must be supplied') }

  // let host = 'https://' + process.env.SERVICE_TARGET_DOMAIN + process.env.OPTIMIZED_IMAGE_API_ENDPOINT
  const host = ''
  let base = host + process.env.OPTIMIZED_IMAGE_API_ENDPOINT + '/' + mediaFile.id + '?auto=compress'

  if (process.env.IS_OFFLINE) {
    base = process.env.API_ENDPOINT + process.env.SERVICE_STAGE + process.env.OPTIMIZED_IMAGE_API_ENDPOINT + '/' + mediaFile.id + '?auto=compress'
  }

  if (width) base += `&w=${Math.round(Math.max(1, width))}`
  if (height) base += `&h=${Math.round(Math.max(1, height))}`

  if (!width && !maxWidth && !height && !maxHeight && breakpointSize) {
    const currentBreakpoint = breakpointSize.name
    const threshold = breakpointSize.thresholds[currentBreakpoint] || 4096
    maxWidth = threshold * window.devicePixelRatio
  }

  if (!width && maxWidth) {
    const width = getWidth(mediaFile)
    if (width > maxWidth) base += `&w=${Math.round(Math.max(1, maxWidth))}`
  }

  if (!height && maxHeight) {
    const height = getHeight(mediaFile)
    if (height > maxHeight) base += `&h=${Math.round(Math.max(1, maxHeight))}`
  }

  if (quality) base += `&q=${Math.round(Math.max(1, Math.min(100, quality)))}`
  if (aspectRatio) base += `&ar=${aspectRatio}`
  if (blur) base += `&blur=${Math.max(0, Math.min(2000, Math.round(blur)))}`

  if (fit && fitTypes.indexOf(fit) !== -1) {
    base += `&fit=${fit}`

    if (fit === FIT_FILL) {
      base += `&fill-color=${fillColor}`
    }
  }

  if (crop && cropTypes.indexOf(crop) !== -1) {
    base += `&crop=${crop}`

    if (crop === CROP_FOCALPOINT) {
      base += `&fp-x=${Math.max(0, Math.min(1, focalPoint.x))}`
      base += `&fp-y=${Math.max(0, Math.min(1, focalPoint.y))}`
    }
  }

  if (format) {
    // manually set format overrides everything
    base += `&fm=${format}`
  } else {
    // auto format to webp if certains conditions are met
    const hasWebpSupport = window.Modernizr && !!window.Modernizr.webp // browser must support webp!
    const isGif = mediaFile.mimeType === 'image/gif' || mediaFile.id.indexOf('.gif') !== -1 // gifs to webp don't translate well
    const isSvg = mediaFile.mimeType === 'image/svg+xml' || mediaFile.id.indexOf('.svg') !== -1 // svg to webp are not optimal
    const isWebp = mediaFile.mimeType === 'image/webp' || mediaFile.id.indexOf('.webp') !== -1 // image is already a webp
    const isPng = mediaFile.mimeType === 'image/png' || mediaFile.id.indexOf('.png') !== -1 // we need to check support for transparent webp
    const hasWebpAlphaSupport = window.Modernizr && window.Modernizr.webp.alpha

    if (hasWebpSupport && !isGif && !isSvg && !isWebp && (!isPng || (isPng && hasWebpAlphaSupport))) {
      base += '&fm=webp'
    }
  }
  return base
}

export default optimizedImageURL
