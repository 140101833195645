<template>
  <div class="lang-switcher text-uppercase">
    <small class="current">{{ $route.params.language }}</small>
    <div class="languages">
      <small
        v-for="lang in availableLanguages"
        :key="lang"
        @click="switchLanguage(lang)"
      >
        {{ lang }}
      </small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LangSwitcher',
  computed: {
    availableLanguages () {
      const allLang = []
      Object.keys(this.$language.available).forEach(lang => {
        if (lang !== this.$route.params.language) {
          allLang.push(lang)
        }
      })
      return allLang
    }
  },
  methods: {
    switchLanguage (language) {
      this.$router.push({ params: { language } })
    }
  }
}
</script>

<style lang="scss" scoped>
  .lang-switcher {
    position: fixed;
    top: rem($ui-distance-mobile);
    right: rem($ui-distance-mobile);
    z-index: $z-room-ui;
    text-align: right;
    cursor: default;

    @include breakpoint('md-and-up') {
      top: rem($ui-distance-desktop + 9px);
      right: rem($ui-distance-desktop);
    }
  }

  .languages {
    position: absolute;
    top: 100%;
    right: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;

    cursor: pointer;

    .lang-switcher:hover & {
      pointer-events: auto;
      opacity: 1;
    }
  }
</style>
