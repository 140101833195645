<template>
  <div class="app v-application--is-ltr" v-show="!$root.platform.prerenderer && isReady">
    <transition name="fade-transition" mode="out-in" appear>
      <router-view v-if="isReady && !!$root.Setting" />
    </transition>
    <Logo />
    <ButtonMenu />
    <Map />
    <AudioIcon v-if="$root.Setting && $root.Setting.ambienceAudio && $route.name === 'Room' && !$root.isApp" />
    <TutorialIcon v-if="$route.name === 'Room'" />
    <LangSwitcher />
    <VersionInfo
      v-if="!isProduction"
      :version="$root.process.env.GIT_VERSION"
      :commit-hash="$root.process.env.GIT_COMMIT_HASH"
    />
  </div>
</template>

<script>
import ShareMixin from '@/mixins/share-mixin'
import VersionInfo from '@monogrid/vue-lib/lib/components/VersionInfo'
import Logo from '@/atoms/Logo'
import Map from '@/molecules/Map'
import LangSwitcher from '@/atoms/LangSwitcher'
import ButtonMenu from '@/atoms/ButtonMenu'
import AudioIcon from '@/atoms/AudioIcon'
import TutorialIcon from '@/atoms/TutorialIcon'

let initializedModernizr = false

export default {
  name: 'App',
  mixins: [ShareMixin],
  components: { VersionInfo, Logo, Map, LangSwitcher, AudioIcon, TutorialIcon, ButtonMenu },
  data () {
    return {
      // this flag is used to show the app once the preloading
      // (if present) is finished
      isReady: false
    }
  },
  mounted () {
    // set this.isReady = true
    // when all stuff that needs to be loaded for the app is loaded
    // if you need to preload stuff, delete this line and set to true later
    this.isReady = true
  },
  updated () {
    // if not prerenderer
    if (!this.$root.platform.prerenderer && window.Modernizr && !initializedModernizr) {
      // add custom modernizr tests
      initializedModernizr = true
      // window.Modernizr.addAsyncTest('avif', this.testAvif)
      for (const key in this.$root.platform) {
        window.Modernizr.addTest(key, () => {
          return this.$root.platform[key]
        })
      }
    }
  },
  computed: {
    isProduction () {
      return window && window.location.host === 'virtualtourgucciarchive.gucci.com'
    }
  },
  watch: {
    isReady (value) {
      // hide / show preloader
      this.$root.preloader.visible = !value
    },
    $route (to, from) {
      if (window.location.href.includes('?app=true') || this.$route.query.app) {
        this.$root.isApp = true
      }
    }
  },
  metaInfo () {
    const title = this.$root.Setting ? this.$root.Setting.shareTitle : 'Gucci Archivio'
    const baseShare = {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: '',
      // all titles will be injected into this template
      titleTemplate: (titleChunk) => (titleChunk ? `${titleChunk} - ${title}` : title),
      base: { href: '/' },
      meta: [
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        // twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@gucci' },
        { name: 'twitter:creator', content: '@gucci' },
        // og tags
        { vmid: 'ogtype', property: 'og:type', content: 'website' },
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: title }
      ],
      htmlAttrs: {
        lang: this.$language.current
      }
    }
    const share = this.$root.Setting ? this.getShare({ description: this.$root.Setting.shareDescription, image: this.$root.Setting.shareImage }) : { meta: [] }
    return {
      ...baseShare,
      ...share,
      meta: [
        ...baseShare.meta,
        ...share.meta
      ]
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Gucci Sans Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    url('assets/fonts/GucciSansProPoster-Book.woff2') format('woff2'),
    url('assets/fonts/GucciSansProPoster-Book.woff') format('woff');
}

@font-face {
  font-family: 'Gucci Sans Pro';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src:
    url('assets/fonts/GucciSansProPoster-LightItalic.woff2') format('woff2'),
    url('assets/fonts/GucciSansProPoster-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Gucci Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src:
    url('assets/fonts/GucciSansProPoster-MediumItalic.woff2') format('woff2'),
    url('assets/fonts/GucciSansProPoster-MediumItalic.woff') format('woff');
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.font-italic {
  font-style: italic;
  font-weight: 300;
}

.text-uppercase {
  text-transform: uppercase;
}

::selection {
  color: $c-primary;
  background: $c-white;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  font-family: $body-font-family;
  font-size: rem(16px);
  line-height: rem(24px);
  color: $c-white;
}

.app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

h1,
.h1 {
  font-size: rem(50px);
  line-height: rem(60px);
  font-weight: normal;
}

h2,
.h2,
.h2-spaced {
  font-size: rem(30px);
  line-height: 1.2;
  font-weight: normal;
}

.h2-spaced {
  letter-spacing: 0.05em;
}

h3 {
  font-size: rem(20px);
  line-height: 1.5;
  font-weight: normal;
}

button {
  outline: none;
}
</style>
