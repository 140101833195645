<template>
  <button
    v-if="MapSetting"
    v-show="$route.name === 'Room'"
    @click="onClick()"
    :aria-label="MapSetting.openMap"
    type="button"
    class="open text-uppercase"
  >
    {{ MapSetting.openMap }}
  </button>
</template>

<script>
import MapQuery from '@/graphql/GetMapSetting.gql'
import { pushGtmEvent } from '@/utils/analytics-events'
import { find } from 'lodash'

export default {
  name: 'ButtonMenu',
  apollo: {
    MapSetting: {
      query: MapQuery,
      variables () {
        return {
          id: 'fbbf8c8d-3421-4c8d-8197-796cce822ec5',
          status: 'PUBLISHED'
        }
      }
    }
  },
  methods: {
    pushGtmEvent,
    onClick () {
      this.$events.$emit('show-map')
      if (!this.$root.Floors) return false
      const floor = find(this.$root.Floors.items, floor => floor._slug === this.$route.params.floor)
      const room = find(floor.rooms, room => room.reference._slug === this.$route.params.room)
      pushGtmEvent(`Clicks on ${this.MapSetting.openMap}`, floor.title, room.reference.title)
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  position: fixed;
  bottom: rem(80px);
  left: 50%;
  transform: translateX(-50%);
  font-size: rem(16px);
  letter-spacing: 1.5px;
  font-weight: 100 !important;

  @include breakpoint('md-and-up') {
    bottom: rem($ui-distance-desktop + 5px);
  }

  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 40%;
    background: $c-white;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: left center;
    transition: width 0.2s ease-out;
  }

  &:hover {
    &::after {
      width: 90%;
    }
  }
}
</style>
