<template>
  <button
    @click="onClick()"
    type="button"
    aria-label="Open tutorial"
    class="tutorial-icon"
  >
    <TutorialSvg />
  </button>
</template>

<script>
import { pushGtmEvent } from '@/utils/analytics-events'
import TutorialSvg from '@/assets/svg/tutorial-icon.svg'

export default {
  name: 'TutorialIcon',
  components: { TutorialSvg },
  data () {
    return {
      isVisible: false
    }
  },
  methods: {
    pushGtmEvent,
    onClick () {
      this.$events.$emit('tutorial-show')
      this.pushGtmEvent('Clicks on Tutorial icon', '-', '-')
    }
  }
}
</script>

<style lang="scss" scoped>
.tutorial-icon {
  position: fixed;
  bottom: rem(50px);
  left: rem($ui-distance-mobile + 36px);
  z-index: $z-room-ui;
  width: rem(20px);
  height: rem(20px);
  display: flex;
  justify-content: space-between;

  @include breakpoint('md-and-up') {
    left: auto;
    right: rem($ui-distance-desktop);
    bottom: auto;
    top: rem($ui-distance-desktop);
  }
}
</style>
