<template>
  <div
    v-if="!!($root.Floors && $root.Floors.items && currentFloor)"
    :class="{ isOpen: isOpen }"
    class="map"
  >
    <div class="map-gradient-top"></div>
    <div class="map-container">
      <ButtonClose @click.stop="hide" :isMap="true" />
      <MapFloorsList
        :list="$root.Floors.items"
        :current="currentFloor"
        @click-item="onChangeFloor"
      />
      <div class="floor-container">
        <MapFloorImage
          v-bind="currentFloor"
          :roomHover="currentRoomID"
          @dot-hover="onRoomHover"
        />
      </div>
      <v-container class="map-content" fluid :fill-height="$vuetify.breakpoint.mdAndUp">
        <v-row align-md="center">
          <v-col cols="12" md="5" offset-md="7" lg="4" offset-lg="8">
            <MapFloorRooms
              v-bind="currentFloor"
              :roomHover="currentRoomID"
              @link-hover="onRoomHover"
            />
          </v-col>
        </v-row>
      </v-container>
      <div class="current-location">
        <transition name="slide" >
          <span
            :key="currentTitle"
            v-html="currentTitle"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonClose from '@/atoms/ButtonClose'
import MapFloorsList from '@/atoms/MapFloorsList'
import MapFloorImage from '@/atoms/MapFloorImage'
import MapFloorRooms from '@/atoms/MapFloorRooms'
import { gsap } from 'gsap'

export default {
  name: 'Map',
  components: { ButtonClose, MapFloorsList, MapFloorImage, MapFloorRooms },
  data () {
    return {
      isOpen: false,
      selectedFloor: null,
      roomHover: null
    }
  },
  computed: {
    currentTitle () {
      if (this.currentFloor && this.currentRoom) {
        return `${this.currentFloor.title} | <strong>${this.currentRoom.reference.shareTitle}</strong>`
      } else {
        return null
      }
    },
    currentFloor () {
      if (this.selectedFloor) {
        return this.selectedFloor
      }
      if (this.$route.name === 'Room' && this.$root.Floors.items) {
        let current = null
        this.$root.Floors.items.forEach(floor => {
          if (floor._slug === this.$route.params.floor) {
            current = floor
          }
        })
        return current
      }
      return null
    },
    currentRoom () {
      if (this.roomHover) {
        return this.roomHover
      }
      if (this.$route.name === 'Room' && this.$root.Floors.items) {
        let current = null
        this.$root.Floors.items.forEach(floor => {
          if (floor._slug === this.currentFloor._slug) {
            floor.rooms.forEach(room => {
              if (room.reference._slug === this.$route.params.room) {
                current = room
              }
            })
          }
        })
        return current
      }
      return null
    },
    currentRoomID () {
      return this.currentRoom ? this.currentRoom.reference.id : null
    }
  },
  mounted () {
    this.$events.on('show-map', this.show)
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  },
  watch: {
    $route () {
      this.hide()
    }
  },
  methods: {
    show () {
      if (this.isOpen) return
      this.isOpen = true
      this.tl && this.tl.kill()
      this.tl = gsap.timeline({
        defaults: { duration: 0.4 },
        onStart: () => { this.isAnimating = true },
        onComplete: () => { this.isAnimating = false }
      })
      this.tl.fromTo('.map', { autoAlpha: 0 }, { autoAlpha: 1 })
      this.tl.fromTo('.map .image-wrapper', { autoAlpha: 0 }, { autoAlpha: 1, clearProps: 'visibility, opacity' })
      this.tl.fromTo('.map .floors-list', { autoAlpha: 0, y: -20 }, { autoAlpha: 1, y: 0, delay: 0.1, clearProps: 'transform' })
      this.tl.fromTo('.map .room-link', { autoAlpha: 0 }, { autoAlpha: 1, stagger: 0.1, clearProps: 'visibility, opacity' })
      this.tl.fromTo('.map .current-location', { autoAlpha: 0, y: -20 }, { autoAlpha: 1, y: 0, clearProps: 'transform' })
    },
    hide () {
      if (!this.isOpen) return
      this.isOpen = false
      this.tl && this.tl.kill()
      this.tl = gsap.timeline({ defaults: { duration: 0.4 } })
      this.tl.to('.map', { autoAlpha: 0 })
      this.selectedFloor = null
    },
    onChangeFloor (floor) {
      if (this.isAnimating) return
      this.tl && this.tl.kill()
      this.tl = gsap.timeline({
        defaults: { duration: 0.4 },
        onStart: () => { this.isAnimating = true }
      })
      this.tl.to(
        '.map-container .image-wrapper, .map-container .map-floor-rooms',
        {
          opacity: 0,
          onComplete: () => {
            this.selectedFloor = floor
            setTimeout(() => {
              this.tl.fromTo(
                '.map-container .image-wrapper, .map-container .map-floor-rooms, .map-container .room-link',
                { opacity: 0 },
                { opacity: 1, stagger: 0.1, clearProps: 'opacity', onComplete: () => { this.isAnimating = false } }
              )
            }, 200)
          }
        }
      )
    },
    onRoomHover (room) {
      this.roomHover = room
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: $z-map;
  background: $bg-gradient;
  pointer-events: none;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;

  &.isOpen {
    pointer-events: auto;
  }
}

.map-gradient-top {
  @include breakpoint ('xs-only') {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-map;
    background: linear-gradient($c-primary 50%, rgba(255, 255, 255, 0));
    height: 80px;
    width: 100%;
  }
}

.map-container {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.map-content {
  padding: $ui-distance-mobile;

  @include breakpoint('md-and-up') {
    padding: $ui-distance-desktop;
    padding-top: rem(80px);
    max-height: 100%;
    overflow: auto;
    /* stylelint-disable-next-line */
    -webkit-mask-image: -webkit-linear-gradient(top,rgba(0,0,0,0) 70px,#000 120px,#000 calc(100% - 100px),rgba(0,0,0,0) 100%);
  }
}

.floor-container {
  @include breakpoint('md-and-up') {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @include breakpoint('lg-and-up') {
    width: 60%;
  }
}

.current-location {
  position: fixed;
  left: rem(30px);
  bottom: rem(30px);
  width: 50%;
  @include breakpoint('sm-and-down') {
    display: none;
  }

  span {
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 0;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s, opacity 0.5s;
}

.slide-enter {
  opacity: 0;
  transform: translateY(20px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
