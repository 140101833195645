export const PUBLIC_ACL = 0
export const REGISTERED_ACL = 1
export const CREATOR_ACL = 2
export const EDITOR_ACL = 3
export const ADMIN_ACL = 4
export const NOONE_ACL = 5

/**
 * Given a resource Access Level
 * It compares the logged in user's permissions with the requested ACL
 *
 * @param {*} user
 * @param {*} requestedACL
 * @param {*} contentCreator
 */
export const userHasRequestedACL = (user, requestedACL, contentCreator) => {
  if (isNaN(requestedACL) || requestedACL === null || requestedACL === undefined) {
    throw new Error('You must define a valid ACL to check against')
  }

  if (!user || (user && !user.signInUserSession)) {
    // no user was passed, we let PUBLIC_ACL pass
    return requestedACL === PUBLIC_ACL
  }
  const claims = user.signInUserSession.idToken.payload
  let userACL = PUBLIC_ACL // start from the minumim acl
  if (claims['cognito:groups'] && claims['cognito:groups'].length > 0 && claims['cognito:groups'].indexOf(process.env.ADMINS_GROUP_NAME) !== -1) {
    userACL = ADMIN_ACL
  } else if (claims['cognito:groups'] && claims['cognito:groups'].length > 0 && claims['cognito:groups'].indexOf(process.env.EDITORS_GROUP_NAME) !== -1) {
    userACL = EDITOR_ACL
  } else if (claims.email === contentCreator) {
    userACL = CREATOR_ACL
  } else {
    userACL = REGISTERED_ACL // user has claims but nothing else
  }

  return userACL >= requestedACL
}
