<template>
  <button
    v-on="$listeners"
    :class="{ isMap, absolute }"
    :aria-label="`Close ${isMap ? 'map' : 'hotspot content'}`"
    type="button"
    class="close"
  >
    <CloseSvg />
  </button>
</template>

<script>
import CloseSvg from '@/assets/svg/close.svg'

export default {
  name: 'ButtonClose',
  components: { CloseSvg },
  props: {
    absolute: {
      type: Boolean,
      default: false
    },
    isMap: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.close {
  position: fixed;
  top: rem($ui-distance-desktop);
  right: rem(40px);
  width: rem(20px);
  height: rem(20px);
  z-index: $z-logo;
  transition: transform 0.2s ease-in-out;

  @include breakpoint ('sm-and-down') {
    top: rem($ui-distance-mobile);
    right: rem($ui-distance-mobile);
  }

  &.absolute {
    position: absolute;
    @include breakpoint ('md-and-up') {
      right: rem($ui-distance-desktop);
    }
  }

  &.isMap {
    @include breakpoint ('md-and-up') {
      right: rem($ui-distance-desktop);
    }
  }

  &:hover {
    transform: rotate(180deg);
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
