<template>
  <button
    @click="toggleAudio"
    type="button"
    aria-label="Toggle audio"
    class="audio-icon"
  >
    <span />
    <span />
    <span />
    <span />
    <span />
    <audio
      ref="audio"
      preload="auto"
      :src="'/media/' + $root.Setting.ambienceAudio.id"
      :loop="true"
      :autoplay="true"
      :crossorigin="true"
      @pause="onPause"
      @playing="onPlay"
    />
  </button>
</template>

<script>
import { pushGtmEvent } from '@/utils/analytics-events'
import { gsap } from 'gsap'

export default {
  name: 'AudioIcon',
  data () {
    return {
      isPlaying: false
    }
  },
  beforeDestroy () {
    this.audioBarsTl && this.audioBarsTl.kill()
    this.audioVolumeTl && this.audioVolumeTl.kill()
  },
  mounted () {
    this.$events.on('hotspot-open', () => { this.setVolume(0.4) })
    this.$events.on('hotspot-close', () => { this.setVolume(1) })
    this.createTimeline()
    this.createAudioVolumeTimeline()
  },
  methods: {
    pushGtmEvent,
    createTimeline () {
      this.audioBarsTl = gsap.timeline({ paused: true, repeat: -1 })
      this.audioBarsTl.fromTo(['.audio-icon span'], { autoAlpha: 0.4, scaleY: 0.2 }, { autoAlpha: 1, scaleY: 1, duration: 0.4, stagger: 0.2, ease: 'power1.InOut' })
      this.audioBarsTl.to(['.audio-icon span'], { autoAlpha: 0.4, scaleY: 0.2, duration: 0.4, stagger: 0.2, ease: 'power1.InOut' }, 0.4)
      this.audioBarsTl.addLabel('play')
      this.audioBarsTl.addLabel('pause', 0.7)
    },
    createAudioVolumeTimeline () {
      this.audioVolumeTl = gsap.timeline({ paused: true })
      this.audioVolumeTl.addLabel('pause')
      this.audioVolumeTl.fromTo(this.$refs.audio, { volume: 0 }, { volume: 1, duration: 0.4 })
      this.audioVolumeTl.addLabel('play')
    },
    toggleAudio () {
      this.isPlaying ? this.pause() : this.play()
      pushGtmEvent(`Clicks on Audio ${this.isPlaying ? 'OFF' : 'ON'}`, '-', '-')
    },
    play () {
      this.$refs.audio.play()
    },
    pause () {
      this.audioBarsTl.tweenTo('pause')
      this.audioVolumeTl.tweenTo('pause', { onComplete: () => { this.$refs.audio.pause() } })
    },
    setVolume (newVolume) {
      if (this.$refs.audio) {
        gsap.to(this.$refs.audio, { volume: newVolume, duration: 1 })
      }
    },
    onPlay () {
      this.isPlaying = true
      this.audioBarsTl && this.audioBarsTl.play()
      this.audioVolumeTl.tweenTo('play')
    },
    onPause () {
      this.isPlaying = false
      this.audioBarsTl.tweenTo('pause', { onComplete: () => { this.audioBarsTl && this.audioBarsTl.pause() } })
    }
  }
}
</script>

<style lang="scss" scoped>
.audio-icon {
  position: fixed;
  bottom: rem(50px);
  left: rem($ui-distance-mobile);
  z-index: $z-room-ui;
  width: rem(20px);
  height: rem(20px);
  display: flex;
  justify-content: space-between;

  @include breakpoint('md-and-up') {
    left: auto;
    right: rem($ui-distance-desktop + 40px);
    bottom: auto;
    top: rem($ui-distance-desktop);
  }
}

span {
  position: relative;
  display: block;
  height: 100%;
  width: 1px;
  background: #fff;
  transform-origin: center center;
}
</style>
