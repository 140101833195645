import packageConfig from '../package.json'

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import Platform from '@monogrid/js-utils/lib/Platform'

import router from './plugins/router.js'
import './plugins/locale.js'
import VueMeta from 'vue-meta'
import VueEvents from 'vue-event-handler'
import Preloader from './preloader'
import onlineApolloProvider from './services/CMS'
import SettingQuery from '@/graphql/GetSetting.gql'
import ListFloorQuery from '@/graphql/ListFloor.gql'

import CMSOffline from './services/CMSOffline'
import cmsOfflineCatalog from './services/CMSOfflineCatalog.json'
import cmsOfflineSchema from './services/CMSOfflineSchema.graphql'

// usage: https://vuetifyjs.com/
import vuetify from './plugins/vuetify'
// usage: https://github.com/declandewet/vue-meta
Vue.use(VueMeta)
// usage: https://github.com/sandeepk01/vue-event-handler
Vue.use(VueEvents)

//
//
// Initialization of the preloader app (placed on the HTML)
//
//
const preloaderInstance = new Vue(Preloader)

// management of languages
router.beforeEach((to, from, next) => {
  // set current language on change route
  // preloaderInstance.visible = true
  if (packageConfig.multilanguage) {
    if (to.params.language) {
      Vue.config.language = to.params.language || 'en'
    }
  } else {
    Vue.config.language = 'en'
  }
  next()
})
router.afterEach((to, from) => {
  // preloaderInstance.visible = false
})

const USE_OFFLINE = true
//
//
// Initialization of the app (placed on the HTML)
//
//
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  vuetify,
  apolloProvider: USE_OFFLINE ? new CMSOffline(cmsOfflineCatalog, cmsOfflineSchema).getProvider() : onlineApolloProvider,
  apollo: {
    Setting: {
      query: SettingQuery,
      variables () {
        return {
          status: 'PUBLISHED',
          id: 'fbbf8c8d-3421-4c8d-8197-796cce822ec5'
        }
      }
    },
    Floors: {
      query: ListFloorQuery,
      variables () {
        return {
          status: 'PUBLISHED'
        }
      }
    }
  },
  data () {
    return {
      preloader: preloaderInstance,
      isApp: false,
      process: {
        env: process.env
      }
    }
  },

  mounted () {
    this.previousRoomSlug = ''
  },
  computed: {
    platform () {
      return Platform
    },
    multilanguage () {
      return packageConfig.multilanguage
    }
  },
  // this block allows passing props to the main App
  // these props are set by html-webpack-plugin in the html
  // and the values are taken from git-revision-webpack-plugin
  render (createElement) {
    return createElement(App, {
      props: {
        preloader: preloaderInstance
      }
    })
  }
})
